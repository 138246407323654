/* Cliente.css */

.gradient-background {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
}

.container {
  margin-top: 24px;
}

.title {
  margin-bottom: 16px;
  color: #000000; /* Color negro para el título */
}

.search-container {
  display: flex;
  align-items: center;
  gap: 16px; /* Espacio entre elementos */
  flex-wrap: wrap;
}

.form-control {
  min-width: 120px;
}

.print-button {
  background-color: #4caf50;
  color: #ffffff;
}

.table {
  min-width: 650px;
}

.table-header-cell {
  background-color: #3f51b5;
  color: #ffffff;
}

.styled-table-row:hover {
  background-color: #f5f5f5;
}

.search {
  width: 650px; 
  margin-right: 10px; 
}

.table .MuiTableCell-root {
  font-size: 12px; 
}
