/* styles/LoginStyle.css */

@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

* {
  box-sizing: border-box;
}

body {
  background-color: steelblue;
}

.body-login {
  color: #fff;
  font-family: 'Muli', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.container-login {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.container-login h1 {
  text-align: center;
  margin-bottom: 30px;
}

.container-login a {
  text-decoration: none;
  color: lightblue;
}

.btn {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  background: lightblue;
  padding: 15px;
  font-family: inherit;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
}

.btn:focus {
  outline: 0;
}

.btn:active {
  transform: scale(0.98);
}

.text {
  margin-top: 30px;
}

.form-control-login {
  position: relative;
  margin: 20px 0 40px;
  width: 300px;
}

.form-control-login input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px #fff solid;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  color: #fff;
  position: relative;
  z-index: 100;
}

.form-control-login input:focus,
.form-control-login input:valid {
  outline: 0;
  border-bottom-color: lightblue;
}

.form-control-login label {
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
}

.form-control-login label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control-login input:focus + label span,
.form-control-login input:valid + label span {
  color: lightblue;
  transform: translateY(-30px);
}

.logo-container {
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  width: 100%;
  margin-bottom: 20px;
}
