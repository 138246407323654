body {
    font-family: Arial, sans-serif;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.drawer-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 8px;
}

.container-styled {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.title {
    text-align: center;
    margin-bottom: 16px;
}

.button-section {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.table-header-cell {
    background-color: #1976d2; /* Primary color */
    color: white;
    text-align: center;
}

.table-styled {
    min-width: 650px;
    margin: 0 auto;
}

.dialog-form-control {
    margin: 8px 0;
}
