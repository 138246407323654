/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

.navbar-title {
  margin: 0;
}

.logout-button {
  background-color:steelblue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}
/* Estilos globales para la aplicación */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Estilos específicos para el MainApp */
.root {
  display: flex;
}

.appBar {
  z-index: 1300; /* Asume que el drawer tiene z-index 1200 */
}

.drawer {
  width: 240px;
  flex-shrink: 0;
  white-space: nowrap;
}

.drawerOpen .drawerPaper {
  width: 240px;
  transition: width 0.3s;
}

.drawerHeader {
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: flex-end;
}

.drawerHeaderSpacer {
  margin-top: 64px; /* Ajusta según la altura de tu AppBar */
}

.drawerPaper {
  width: 240px;
}

.content {
  flex-grow: 1;
  padding: 16px; /* Puedes ajustar según tus necesidades */
  transition: margin 0.3s;
}
